import React from "react";
import {useQuery} from "@apollo/client";
import {GET_BRANDS} from "../../graphql/graphql-queries";
import {Brand} from "../../types";
import {Card} from "../card";
import Loader from "../loader/Loader";
import "./style.scss"

interface IChooseBrand {
    onClick: (brand: Brand) => void
}

interface BrandsData {
    brands: Brand[]
}

export const ChooseBrand: React.FC<IChooseBrand> = ({onClick}) => {
    const {data, loading} = useQuery<BrandsData>(GET_BRANDS)
    if (loading) {
        return <Loader/>
    }
    return (
        <div className="brand-group">
            {data?.brands.map((brand, index) =>
                <span className="brand" key={index} onClick={() => onClick(brand)}>
                    <Card src={brand.logoUrl}/>
                </span>
            )}
        </div>
    )
}