import React from "react"
import "./search.scss"
import SearchIcon from "@mui/icons-material/Search";

interface ISearch {
    onChange: (input: React.ChangeEvent<HTMLInputElement>) => void
}

const Search:React.FC<ISearch> = ({onChange}) => (
    <div className="search">
        <input type="text" placeholder="Поиск..." onChange={onChange}/>
        <SearchIcon className="icon"/>
    </div>
)

export default Search