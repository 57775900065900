import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {email,token}
    }
`

export const UPLOAD_IMAGE_MUTATION = gql`
    mutation($files: [Upload!]!) {
        uploadImage(files: $files) {
            key,
            url
        }
    }
`;

export const GET_BRANDS = gql`
    query GetBrands{
        brands {
            id
            name
            logo
            logoUrl
        }
    }
`

export const GET_BRAND = gql`
    query GetBrand($id: Int!){
        brand(id: $id) {
            id
            name
            logo
            logoUrl
        }
    }
`

export const SAVE_BRAND_MUTATION = gql`
    mutation ($brand: SaveBrandInput!) {
        saveBrand(input: $brand) {
            id
            name
            logo
        }
    }
`;

export const DELETE_BRAND_MUTATION = gql`
    mutation ($id: Int!) {
        deleteBrand(id: $id)
    }
`;

export const GET_ALL_PRODUCTS = gql`
    query GetProducts{
        products {
            id,
            name,
            brand {
                name
            }
            type,
            connect {
                id
            },
            code {
                name
            },
            size {
                id
            }
        }
    }
`

export const GET_PRODUCT = gql`
    query GetProduct($id: Int!){
        product(id: $id) {
            id
            name
            type
            brand {
                id
                name
            }
            code {
                id
                name
            }
            connect {
                id
            }
            size {
                id
            }
        }
    }
`

export const SAVE_PRODUCT_MUTATION = gql`
    mutation SaveProduct($product: SaveProductInput!) {
        saveProduct(input: $product) {
            id
        }
    }
`

export const DELETE_PRODUCT_MUTATION = gql`
    mutation ($id: Int!) {
        deleteProduct(id: $id)
    }
`;

export const GET_ALL_SIZES = gql`
    query GetSizes {
        sizes {
            id
            products {
                id
                name
            }
        }
    }
`

export const GET_SIZE = gql`
    query GetSize($id: Int!) {
        size(id: $id) {
            id
            content
            products {
                id
                name
            }
        }
    }
`

export const GET_PRODUCTS_NAMES_AND_IDS = gql`
    query GetProductsNamesAndIds {
        products{
            id
            name
        }
    }
`

export const SAVE_SIZE_MUTATION = gql`
    mutation SaveSize($size: SaveSizeInput!) {
        saveSize(input: $size) {
            id
        }
    }
`

export const DELETE_SIZE_MUTATION = gql`
    mutation DeleteSize($id: Int!) {
        deleteSize(id: $id)
    }
`

export const GET_CONNECTIONS = gql`
    query GetConnections {
        connections {
            id
            products {
                id
                name
            }
        }
    }
`

export const GET_CONNECTION = gql`
    query GetConnection($id: Int!) {
        connection(id: $id) {
            id
            content
            products {
                id
                name
            }
        }
    }
`

export const SAVE_CONNECTION_MUTATION = gql`
    mutation SaveConnection($connection: SaveConnectInput!){
        saveConnection(input: $connection) {
            id
        }
    }
`

export const DELETE_CONNECTION_MUTATION = gql`
    mutation DeleteConnection($id: Int!) {
        deleteConnection(id: $id)
    }
`

export const GET_CODES = gql`
    query GetCodes {
        codes {
            id
            name
            products {
                id
                name
            }
        }
    }
`

export const GET_CODE = gql`
    query GetCode($id: Int!) {
        code(id: $id) {
            id
            name
            content
            products {
                id
                name
            }
        }
    }
`

export const SAVE_CODE_MUTATION = gql`
    mutation SaveCode($code: SaveCodeInput!){
        saveCode(input: $code) {
            id
        }
    }
`

export const DELETE_CODE_MUTATION = gql`
    mutation DeleteCode($id: Int!) {
        deleteCode(id: $id)
    }
`

export const GET_PRODUCTS_WITH_FILTER = gql`
    query GetProducts($filter: ProductFilter){
        products(where: $filter) {
            id,
            name
        }
    }
`

export const GET_PRODUCT_PUBLIC = gql`
    query GetProductPublic($id: Int!) {
        product(id: $id) {
            size {
                id
                content
            }
            connect {
                id
                content
            }
            code {
                id
                content
            }
        }
    }
`
export const GET_BRANDS_SIZES_CONNECTIONS_CODES = gql`
    query GetBrandsSizesConnectionsCodes{
        brands{
            id
            name
        }
        sizes {
            id
        }
        connections {
            id
        }
        codes {
            id
            name
        }
    }
`

export const GET_CURRENT_USER = gql`
    query Me{
        me{
            email
        }
    }`