import React from "react"
import {DataGrid, GridCellParams, GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {useNavigate} from "react-router-dom";
import {Product} from "../../../types";
import {ProductPath} from "../../../constants";
import "./../list.scss"
import Actions from "../actions/Actions";
import clsx from 'clsx';

interface IProductTable {
    products: Product[]
    deleteProduct: (id: string | number) => void;
}

const ProductTable: React.FC<IProductTable> = ({products, deleteProduct}) => {
    const navigate = useNavigate()
    const actionColumn = [
        {
            field: "action", headerName: "Действие", width: 180, sortable: false, renderCell: (params: GridValueGetterParams) => {
                return (
                    <Actions editCallback={()=>navigate(ProductPath + "/" + params.id)} removeCallback={() => deleteProduct(params.id)}/>
                )
            }
        }
    ]

    return (
        <div className="list">
            <div style={{width: '100%'}}>
                <DataGrid
                    rows={products}
                    columns={columns.concat(actionColumn)}
                    pageSize={50}
                    autoHeight={true}
                    rowsPerPageOptions={[50]}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    sx={{border: 0}}
                />
            </div>
        </div>
    )
}

const columns: GridColDef[] = [
    {field: 'id', headerName: 'ID', width: 70},
    {field: 'name', headerName: 'Имя', width: 300},
    {
        field: 'brand', headerName: 'Бренд', width: 100,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.brand?.name || ''}`
    },
    {field: 'type', headerName: 'Тип', width: 70},
    {
        field: 'connect', headerName: 'МБС', width: 70,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.connect?.id || ''}`,
        cellClassName: (params: GridCellParams<string>) => clsx({
            empty: params.value === undefined || params.value === '',
        })
    },
    {
        field: 'code', headerName: 'Ошибки', width: 200,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.code?.name || ''}`,
        cellClassName: (params: GridCellParams<string>) => clsx({
            empty: params.value === undefined || params.value === '',
        })
    },
    {
        field: 'size', headerName: 'Размеры', width: 90,
        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.size?.id || ''}`,
        cellClassName: (params: GridCellParams<string>) => clsx({
            empty: params.value === undefined || params.value === '',
        })
    }
];


export default ProductTable