import React from "react";
import "./sidebar.scss"
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import StraightenIcon from '@mui/icons-material/Straighten';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ReportIcon from '@mui/icons-material/Report';
import LogoutIcon from '@mui/icons-material/Logout';
import {Link} from "react-router-dom";
import {BrandPath, CodePath, ConnectionPath, ProductPath, SizePath} from "../../constants";
import {useLogout} from "../../graphql/auth";

const Sidebar: React.FC = () => {
    return (
        <div className="sidebar">
            <div className="top">
                <span className="logo">AC <span>SERVICE</span></span>
                <div className="description">Панель администрирования</div>
            </div>
            <div className="center">
                <ul>
                    <p className="title">ОБЩЕЕ</p>
                    <Link to={BrandPath} style={{textDecoration: "none"}}>
                        <li>
                            <StorefrontIcon className="icon"/>
                            <span>Бренды</span>
                        </li>
                    </Link>
                    <Link to={ProductPath} style={{textDecoration: "none"}}>
                    <li>
                        <LocalGroceryStoreIcon className="icon"/>
                        <span>Продукты</span>
                    </li>
                    </Link>
                    <p className="title">ЛИСТЫ</p>
                    <Link to={SizePath} style={{textDecoration: "none"}}>
                    <li>
                        <StraightenIcon className="icon"/>
                        <span>Установочные размеры</span>
                    </li>
                    </Link>
                    <Link to={ConnectionPath} style={{textDecoration: "none"}}>
                    <li>
                        <ElectricalServicesIcon className="icon"/>
                        <span>Питание и МБС</span>
                    </li>
                    </Link>
                    <Link to={CodePath} style={{textDecoration: "none"}}>
                    <li>
                        <ReportIcon className="icon"/>
                        <span>Ошибки</span>
                    </li>
                    </Link>
                    {/*<p className="title">АДМИНИСТРИРОВАНИЕ</p>*/}
                    {/*<li>*/}
                    {/*    <GroupIcon className="icon"/>*/}
                    {/*    <span>Пользователи</span>*/}
                    {/*</li>*/}
                    <p className="title">ПОЛЬЗОВАТЕЛЬ</p>
                    {/*<Link to={UserPath} style={{textDecoration: "none"}}>*/}
                    {/*<li>*/}
                    {/*    <PersonIcon className="icon"/>*/}
                    {/*    <span>Профиль</span>*/}
                    {/*</li>*/}
                    {/*</Link>*/}
                    <li onClick={useLogout()}>
                        <LogoutIcon className="icon"/>
                        <span>Выйти</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar