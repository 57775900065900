import React, {ChangeEventHandler} from "react";
import "./style.scss"
import {useQuery} from "@apollo/client";
import {GET_PRODUCTS_WITH_FILTER} from "../../graphql/graphql-queries";
import Loader from "../../components/loader/Loader";
import {Product, ProductFilter} from "../../types";

interface ProductsData {
    products: Product[]
}

interface IChooseProduct {
    brandName?: string,
    type?: string
    onChange: (id: string) => void
}

export const ChooseProduct: React.FC<IChooseProduct> = ({brandName, type, onChange}) => {
    const filter: ProductFilter = {brand: {name: brandName}, type: type}
    const productsQuery = useQuery<ProductsData>(GET_PRODUCTS_WITH_FILTER, {variables: {filter}})
    const changeHandler: ChangeEventHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(event.target.value)
    }
    return (
        <>
            {productsQuery.data ? <>
                    <div className="select-product">
                        <select onChange={changeHandler}>
                            <option disabled selected>Продукт не выбран</option>
                            {productsQuery.data.products.map(product => <option key={product.id} value={product.id}>{product.name}</option>)}
                        </select>
                    </div>
                </>
                : <Loader/>}
        </>
    )
}