import React from "react"
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {Brand} from "../../types";
import {GET_BRAND} from "../../graphql/graphql-queries";
import BrandForm from "../../components/brand/BrandForm";
import Loader from "../../components/loader/Loader";

interface BrandData {
    brand: Brand
}

const EditBrand: React.FC = () => {
    const {id} = useParams()
    const {data, loading} = useQuery<BrandData>(GET_BRAND, {variables: {id}})
    if (loading) return <Loader/>;

    return (
        <div className="page">
            <div className="pageTop">
                <div className="pageTitle">Редактировать бренд</div>
            </div>
            <BrandForm brand={data?.brand}/>
        </div>
    )
}

export default EditBrand