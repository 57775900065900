import React from "react"
import {Brand, Code, Connect, Product, Size} from "../../../types";
import "./product-form.scss"
import {Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {SubmitHandler, useForm} from "react-hook-form";
import {useMutation, useQuery} from "@apollo/client";
import {
    DELETE_PRODUCT_MUTATION,
    GET_BRANDS_SIZES_CONNECTIONS_CODES,
    SAVE_PRODUCT_MUTATION
} from "../../../graphql/graphql-queries";
import {ProductPath} from "../../../constants";
import {useNavigate} from "react-router-dom";
import Loader from "../../loader/Loader";

interface IProductForm {
    product?: Product
}

interface IProductFormInput {
    name: string,
    brandId: number,
    type: string,
    sizeId: number,
    connectionId: number,
    codeId: number
}

interface ProductFormData {
    brands: Brand[],
    sizes: Size[],
    connections: Connect[],
    codes: Code[],
}

const ProductForm: React.FC<IProductForm> = ({product}) => {
    const {data} = useQuery<ProductFormData>(GET_BRANDS_SIZES_CONNECTIONS_CODES)
    const {register, handleSubmit, formState: {errors}} = useForm<IProductFormInput>({
        defaultValues: {
            name: product?.name,
            type: product?.type,
            sizeId: product?.size?.id,
            connectionId: product?.connect?.id,
            codeId: product?.code?.id
        }
    })
    const navigate = useNavigate();
    const [saveProductMutation, {client}] = useMutation(SAVE_PRODUCT_MUTATION)
    const [deleteProductMutation] = useMutation(DELETE_PRODUCT_MUTATION)
    const onSubmit: SubmitHandler<IProductFormInput> = ({name, brandId, type, sizeId, connectionId, codeId}) => {
        saveProductMutation({
            variables: {
                product: {
                    id: product?.id,
                    name,
                    type,
                    brandId: returnNumberOrUndefined(brandId),
                    sizeId: returnNumberOrUndefined(sizeId),
                    connectionId: returnNumberOrUndefined(connectionId),
                    codeId: returnNumberOrUndefined(codeId)
                }
            }
        }).then(async () => {
            await client.clearStore();
            navigate(ProductPath);
        });
    }

    function returnNumberOrUndefined (value: any) : number | undefined {
        if (value === undefined || value === '') {
            return undefined;
        }
        if (typeof value === 'number') {
            return value;
        }
        return Number(value);
    }

    const deleteProduct = async () => {
        await deleteProductMutation({variables: {id: product?.id}});
        await client.clearStore();
        navigate(ProductPath)
    }
    return (
        <>
            {data ?
                <Box component="form" className="productForm" onSubmit={handleSubmit(onSubmit)}>
                    {product?.id ? <TextField className="formInput" label="ID" defaultValue={product?.id} type="text"
                                              InputProps={{readOnly: true}}/> : <></>}
                    <TextField className="formInput" label="Имя"
                               defaultValue={product?.name} error={!!errors.name} helperText={errors.name?.message}
                               type="text" {...register("name", {required: "Имя не может быть пустым"})}/>
                    <FormControl className="formInput">
                        <InputLabel>Бренд</InputLabel>
                        <Select label="Бренд" defaultValue={product?.brand.id || ''} error={!!errors.brandId}
                                {...register("brandId", {required: "Необходимо выбрать бренд"})}>
                            {data?.brands.map(brand => <MenuItem key={brand.id}
                                                                 value={brand.id}>{brand.name}</MenuItem>)}
                        </Select>
                        <FormHelperText error={!!errors.brandId}>{errors.brandId?.message}</FormHelperText>
                    </FormControl>
                    <FormControl className="formInput">
                        <InputLabel>Тип</InputLabel>
                        <Select defaultValue={product?.type || ''} label="Тип" error={!!errors.type}
                                {...register("type", {required: "Необходимо выбрать тип"})}>
                            <MenuItem value={"RAC"}>RAC</MenuItem>
                            <MenuItem value={"LCAC"}>LCAC</MenuItem>
                        </Select>
                        <FormHelperText error={!!errors.type}>{errors.type?.message}</FormHelperText>
                    </FormControl>
                    <FormControl className="formInput">
                        <InputLabel>Установочные размеры</InputLabel>
                        <Select defaultValue={product?.size?.id || ''}
                                label="Установочные размеры" {...register("sizeId")}>
                            <MenuItem value=''>Нет</MenuItem>
                            {data?.sizes.map(size => <MenuItem key={size.id} value={size.id}>{size.id}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className="formInput">
                        <InputLabel>Питание и межблочная связь</InputLabel>
                        <Select defaultValue={product?.connect?.id || ''}
                                label="Питание и межблочная связь" {...register("connectionId")}>
                            <MenuItem value=''>Нет</MenuItem>
                            {data?.connections.map(connect => <MenuItem key={connect.id}
                                                                        value={connect.id}>{connect.id}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <FormControl className="formInput">
                        <InputLabel>Ошибки</InputLabel>
                        <Select defaultValue={product?.code?.id || ''} label="Ошибки" {...register("codeId")}>
                            <MenuItem value=''>Нет</MenuItem>
                            {data?.codes.map(code => <MenuItem key={code.id} value={code.id}>{code.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Stack direction="column" spacing={2}>
                        {product ?
                            <Button onClick={deleteProduct} variant="outlined" color="error" endIcon={<DeleteIcon/>}
                                    fullWidth>Удалить</Button> : <></>}
                        <Button variant="contained" fullWidth
                                type="submit">{product ? "Сохранить" : "Добавить"}</Button>
                    </Stack>
                </Box>
                : <Loader/>}
        </>
    )
}

export default ProductForm