export const AuthCookieName = "user"

export const ChooseTypePath = "/type"
export const ChooseProductPath = "/product"

export const LoginPath = "/login"

export const AdminPath = "/admin"

export const BrandPath = AdminPath + "/brand"
export const AddBrandPath = BrandPath + "/add"
export const EditBrandPath = BrandPath + "/:id"

export const ProductPath = AdminPath + "/product"
export const AddProductPath = ProductPath + "/add"
export const EditProductPath = ProductPath + "/:id"

export const SizePath = AdminPath + "/size"
export const AddSizePath = SizePath + "/add"
export const EditSizePath = SizePath + "/:id"

export const ConnectionPath = AdminPath + "/connection"
export const AddConnectionPath = ConnectionPath + "/add"
export const EditConnectionPath = ConnectionPath + "/:id"

export const CodePath = AdminPath + "/code"
export const AddCodePath = CodePath + "/add"
export const EditCodePath = CodePath + "/:id"

export const UserPath = AdminPath + "/user"