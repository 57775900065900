import React from "react"
import ListForm, {ListFormData} from "../../components/form/ListForm";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_CONNECTION, GET_PRODUCTS_NAMES_AND_IDS, SAVE_CONNECTION_MUTATION} from "../../graphql/graphql-queries";
import {Connect, Product} from "../../types";
import Loader from "../../components/loader/Loader";
import {ConnectionPath} from "../../constants";

interface ConnectionData {
    connection: Connect
}

interface ProductsData {
    products: Product[]
}

const EditConnection:React.FC = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const connectionQuery = useQuery<ConnectionData>(GET_CONNECTION, {variables: {id}})
    const productsQuery = useQuery<ProductsData>(GET_PRODUCTS_NAMES_AND_IDS)
    const [connectMutation, {client}] = useMutation(SAVE_CONNECTION_MUTATION)
    async function saveConnection(listFormData: ListFormData) {
        await connectMutation({
            variables: {
                connection: {
                    id: listFormData.id,
                    content: listFormData.content,
                    productIds: listFormData.products.map(value => value.id)
                }
            }
        })
        await client.clearStore()
        navigate(ConnectionPath)
    }

    return(
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Редактировать лист питания и МБС</div>
            </div>
            {connectionQuery.data && productsQuery.data ?
                <ListForm
                    element={connectionQuery.data.connection}
                    allProducts={productsQuery.data.products}
                    saveHook={saveConnection}
                />
                : <Loader/>}
        </div>
    )
}

export default EditConnection