import React from "react";
import "./brand-card.scss"
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from "react-router-dom";
import {AddBrandPath} from "../../constants";

const AddBrandCard: React.FC = () => {
    const navigate = useNavigate();
    return <div className="brand-card" onClick={()=> navigate(AddBrandPath)}>
        <div className="add">
            <AddIcon className="icon"/>
            <div>Добавить бренд</div>
        </div>
    </div>
}

export default AddBrandCard