import {Navigate, Outlet, useLocation} from "react-router-dom";
import {LoginPath} from "../constants";
import React from "react";
import {checkExpires, useAuthToken} from "../graphql/auth";

const RequireAuth = () => {
    const {user} = useAuthToken()
    const location = useLocation()
    if (user?.token) {
        checkExpires(user.token)
    }
    return(
        user?.token
        ? <Outlet/>
            : <Navigate to={LoginPath} state={{from: location}}/>
    )
}

export default RequireAuth
