import React, {useState} from "react";
import "./brands.scss"
import BrandCard from "../../components/old-card/BrandCard";
import {useQuery} from "@apollo/client";
import {GET_BRANDS} from "../../graphql/graphql-queries";
import {Brand} from "../../types";
import AddBrandCard from "../../components/old-card/AddBrandCard";

interface BrandData {
    brands: Brand[]
}

const Brands: React.FC = () => {
    const [brands, setBrands] = useState<Brand[] | undefined>()
    useQuery<BrandData>(GET_BRANDS, {
        onCompleted: brandData => setBrands(brandData.brands)
    })
    return (
        <div className="page brands">
            <div className="pageTop">
                <div className="pageTitle">Бренды</div>
            </div>
            <div className="cardGroup">
                {brands?.map((brand, index) => <BrandCard key={index} id={brand.id} name={brand.name} img={brand.logoUrl}/>)}
                <AddBrandCard/>
            </div>
        </div>
    )
}

export default Brands