import React, {useState} from "react"
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_CONNECTION_MUTATION, GET_CONNECTIONS} from "../../graphql/graphql-queries";
import {Connect} from "../../types";
import ListTable from "../../components/list/ListTable";
import {AddConnectionPath, ConnectionPath} from "../../constants";
import Loader from "../../components/loader/Loader";
import {Link} from "react-router-dom";
import {Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

interface ConnectionsData {
    connections: Connect[]
}

const Connections:React.FC = () => {
    const [connections, setConnections] = useState<Connect[]>([])
    const {loading} = useQuery<ConnectionsData>(GET_CONNECTIONS, {
        onCompleted: data => setConnections(data.connections)
    })
    const [deleteMutation, {client}] = useMutation(DELETE_CONNECTION_MUTATION)

    const deleteConnection = async (id: string | number) => {
        setConnections(connections.filter(size => size.id !== id))
        await deleteMutation({variables: {id}})
        await client.clearStore()
    }

    return(
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Питание и межблочная связь</div>
                <Link to={AddConnectionPath} style={{textDecoration: "none"}}>
                    <Fab size="medium" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link>
            </div>
            {loading ? <Loader/> : <ListTable elements={connections} elementHome={ConnectionPath} deleteHook={deleteConnection}/>}
        </div>
    )
}

export default Connections;