import React from "react"
import {useQuery} from "@apollo/client";
import {GET_PRODUCT_PUBLIC} from "../../graphql/graphql-queries";
import {Product} from "../../types";
import ProductTabs from "../tabs/ProductTabs";
import Loader from "../loader/Loader";

interface ProductData {
    product: Product
}

interface IProductPage {
    id: string
}

export const ProductPage:React.FC<IProductPage> = ({id}) => {
    const {data} = useQuery<ProductData>(GET_PRODUCT_PUBLIC, {variables: {id: id}})
    return (
        <>
            {data?.product ? <ProductTabs product={data.product}/> : <Loader/>}
        </>
    )
}