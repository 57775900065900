import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_PRODUCTS_NAMES_AND_IDS, GET_SIZE, SAVE_SIZE_MUTATION} from "../../graphql/graphql-queries";
import {Product, Size} from "../../types";
import Loader from "../../components/loader/Loader";
import ListForm, {ListFormData} from "../../components/form/ListForm";
import {SizePath} from "../../constants";

interface SizeData {
    size: Size,
}

interface ProductsData {
    products: Product[]
}

const EditSize:React.FC = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const sizeQuery = useQuery<SizeData>(GET_SIZE, {variables: {id}})
    const productsQuery = useQuery<ProductsData>(GET_PRODUCTS_NAMES_AND_IDS)
    const [sizeMutation, {client}] = useMutation(SAVE_SIZE_MUTATION)
    async function saveSize(listFormData: ListFormData) {
        await sizeMutation({
            variables: {
                size: {
                    id: listFormData?.id,
                    content: listFormData.content,
                    productIds: listFormData.products.map(value => value.id)
                }
            }
        })
        await client.clearStore()
        navigate(SizePath)
    }

    return(
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Редактировать лист размеров</div>
            </div>
            {sizeQuery.data && productsQuery.data ?
                <ListForm
                    element={sizeQuery.data.size}
                    allProducts={productsQuery.data.products}
                    saveHook={saveSize}
                />
                : <Loader/>}
        </div>
    )
}

export default EditSize