import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {Product} from "../../types";
import parse from "html-react-parser";
import "./style.scss"

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface IProductTabs {
    product: Product
}

const ProductTabs: React.FC<IProductTabs> = ({product: {size, connect, code}}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box className="productTabs" sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Питание и МБС" {...a11yProps(0)} />
                    <Tab label="Размеры" {...a11yProps(1)} />
                    <Tab label="Коды ошибок" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className="editor-content">
                    {connect?.content ? parse(connect.content) : <p>Нет данных</p>}
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="editor-content">
                    {size?.content ? parse(size.content) : <p>Нет данных</p>}
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="editor-content">
                    {code?.content ? parse(code.content) : <p>Нет данных</p>}
                </div>
            </TabPanel>
        </Box>
    );
}

export default ProductTabs
