import React from "react";
import Loader from "../../components/loader/Loader";
import {useMutation, useQuery} from "@apollo/client";
import {GET_PRODUCTS_NAMES_AND_IDS, SAVE_SIZE_MUTATION} from "../../graphql/graphql-queries";
import {Product} from "../../types";
import {useNavigate} from "react-router-dom";
import ListForm, {ListFormData} from "../../components/form/ListForm";
import {SizePath} from "../../constants";

interface ProductsData {
    products: Product[]
}

const AddSize:React.FC = () => {
    const navigate = useNavigate()
    const productsQuery = useQuery<ProductsData>(GET_PRODUCTS_NAMES_AND_IDS)
    const [sizeMutation, {client}] = useMutation(SAVE_SIZE_MUTATION)
    async function saveSize(listFormData: ListFormData) {
        await sizeMutation({
            variables: {
                size: {
                    content: listFormData.content,
                    productIds: listFormData.products.map(value => value.id)
                }
            }
        })
        await client.clearStore()
        navigate(SizePath)
    }
    return (
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Добавить лист размеров</div>
            </div>
            {productsQuery.data ?
                <ListForm
                    allProducts={productsQuery.data.products}
                    saveHook={saveSize}
                />
                : <Loader/>}
        </div>
    )
}

export default AddSize