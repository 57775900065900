import React, {useState} from "react";
import {Code} from "../../types";
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_CODE_MUTATION, GET_CODES} from "../../graphql/graphql-queries";
import {Link} from "react-router-dom";
import {AddCodePath, CodePath} from "../../constants";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/loader/Loader";
import ListTable from "../../components/list/ListTable";


interface CodesData {
    codes: Code[]
}

const Codes:React.FC = () => {
    const [codes, setCodes] = useState<Code[]>([])
    const {loading} = useQuery<CodesData>(GET_CODES, {
        onCompleted: data => setCodes(data.codes)
    })
    const [deleteMutation, {client}] = useMutation(DELETE_CODE_MUTATION)

    const deleteCode = async (id: string | number) => {
        setCodes(codes.filter(size => size.id !== id))
        await deleteMutation({variables: {id}})
        await client.clearStore()
    }

    return (
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Ошибки</div>
                <Link to={AddCodePath} style={{textDecoration: "none"}}>
                    <Fab size="medium" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link>
            </div>
            {loading ? <Loader/> : <ListTable elements={codes} elementHome={CodePath} deleteHook={deleteCode}/>}
        </div>
    )
}

export default Codes