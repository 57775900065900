import {ApolloClient, ApolloLink, InMemoryCache} from "@apollo/client";
import {checkExpires, useAuthToken} from "./auth";
import {User} from "../types";
import {createUploadLink} from "apollo-upload-client";

export const useAppApolloClient = () => {
    const {user} = useAuthToken();
    return new ApolloClient({
        link: authMiddleware(user).concat(httpLink),
        cache
    });
};

const httpLink = createUploadLink({
    uri: '/graphql'}
)
const cache = new InMemoryCache({});

const authMiddleware = (user: User | undefined) =>
    new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        if (user?.token) {
            checkExpires(user.token)
            operation.setContext({
                headers: {
                    authorization: `Bearer ${user?.token}`,
                },
            });
        }

        return forward(operation);
    });