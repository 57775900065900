import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Brand} from "../../types";
import {ChooseBrand} from "../choose-brand";
import {ChooseType} from "../choose-type";
import {ChooseProduct} from "../choose-product";
import {StepConnector, stepConnectorClasses, StepIconProps, styled} from "@mui/material";
import StorefrontIcon from '@mui/icons-material/Storefront';
import AbcIcon from '@mui/icons-material/Abc';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import {ProductPage} from "../product";

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

interface IStep {
    label: string,
    optional?: string,
    component: React.ReactNode
}

export const HorizontalLinearStepper:React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [brand, setBrand] = useState<Brand>();
    const [type, setType] = useState<string>()
    const [productId, setProductId] = useState<string>()

    function handleNext() {
        setActiveStep(activeStep + 1)
    }

    function handleBack() {
        setProductId(undefined)
        setActiveStep(activeStep - 1)
    }

    function handleBrandClick(b: Brand) {
        setBrand(b)
        handleNext()
    }

    function handleTypeClick(type: string) {
        setType(type)
        handleNext()
    }

    const handleReset = () => {
        setBrand(undefined)
        setType(undefined)
        setProductId(undefined)
        setActiveStep(0);
    };

    const steps: IStep[] = [
        {label: 'Бренд', optional: brand?.name, component: <ChooseBrand onClick={handleBrandClick}/>},
        {label: 'Тип', optional: type, component: <ChooseType onClick={handleTypeClick}/>},
        {label: 'Продукт', component: <ChooseProduct brandName={brand?.name} type={type} onChange={setProductId}/>},
    ]
    function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <StorefrontIcon/>,
            2: <AbcIcon />,
            3: <AcUnitIcon />,
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
        },
    }));

    return (
        <Box sx={{width: '100%'}}>
            <Stepper activeStep={activeStep} connector={<ColorlibConnector/>} sx={{ mt: 1, mb: 3 }}>
                {steps.map((step, index) =>
                    <Step key={step.label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon} optional={step.optional && <Typography variant="caption">{step.optional}</Typography>}>{step.label}</StepLabel>
                    </Step>
                )}
            </Stepper>
            {steps.filter((value, index) => index === activeStep)
                .map((step, index) => <div key={index}>{step.component}</div>)}
            {activeStep === steps.length - 1 && productId && <ProductPage id={productId}/>}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Назад
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {activeStep !== 0 &&
                            <Button onClick={handleReset} sx={{ mr: 1 }}>Сбросить</Button>}
                    </Box>
        </Box>
    );
}