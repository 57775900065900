import React from "react"
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import "./attention.scss"

interface IAttention {
    text?: string
}

const Attention: React.FC<IAttention> = ({text}) => (
    <div className="attention">
        <WarningAmberIcon/>
        {text && <div className="text">{text}</div>}
    </div>
)

export default Attention