import React from "react";
import {Editor} from "@tinymce/tinymce-react";
import {useMutation} from "@apollo/client";
import {UPLOAD_IMAGE_MUTATION} from "../../graphql/graphql-queries";
import {UploadResponse} from "../../types";
import Resizer from "react-image-file-resizer";

interface IAdminEditor {
    initialHtmlContent?: string;
    onHtmlContentChange: (html: string) => void
}

interface UploadData {
    uploadImage: UploadResponse
}

const AdminEditor: React.FC<IAdminEditor> = ({initialHtmlContent, onHtmlContentChange}) => {
    const [uploadMutation] = useMutation<UploadData>(UPLOAD_IMAGE_MUTATION)
    const resizeFile = (file: File) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1000,
                1000,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });
    const imageUploadHandler = (blobInfo: any) => new Promise<string>(async (resolve) => {
        const file= await resizeFile(blobInfo.blob())
        uploadMutation({variables: {files: [file]}})
            .then(response => resolve(response.data?.uploadImage.url || ''))
    });
    return (
        <Editor
            onEditorChange={(newValue, editor) => {
                onHtmlContentChange(editor.getContent({format: 'html'}));
            }}
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            initialValue={initialHtmlContent}
            init={{
                menubar: false,
                resize: false,
                statusbar: false,
                placeholder: 'Введите текст...',
                allow_html_in_named_anchor: true,
                invalid_elements: 'div,figure',
                plugins: ['lists', 'table', 'image', 'preview', 'anchor', 'link', 'charmap', 'fullscreen', 'searchreplace',],
                language: 'ru',
                toolbar:
                    'undo redo |' +
                    'blocks | ' +
                    'bold italic underline strikethrough | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist | ' +
                    'image  link anchor | ' +
                    'table charmap | ' +
                    'searchreplace fullscreen preview',
                block_formats: 'Обычный текст=p; Заголовок 1=h1; Заголовок 2=h2; Заголовок 3=h3; Заголовок 4=h4; Заголовок 5=h5',
                images_file_types: 'jpeg,jpg',
                image_dimensions: false,
                object_resizing : false,
                images_upload_handler: imageUploadHandler,
                image_description: false,
                convert_urls: false,
                content_css: '/editor.css',
                body_class: 'editor-content',
            }
        }
        />
    )
}

export default AdminEditor