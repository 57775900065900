import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Product} from "../../types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ICheckboxesTags {
    label: string,
    defaultValue: Product[],
    options: Product[],
    changeHook: (products: Product[]) => void
}

const CheckboxesTags:React.FC<ICheckboxesTags> = ({label, defaultValue, options, changeHook}) => {
    return (
            <Autocomplete
                multiple
                isOptionEqualToValue = {(option, value) => option.name === value.name}
                onChange={(event, value) => changeHook(value as Array<Product>)}
                defaultValue={defaultValue}
                options={options}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={label} />
                )}
            />
    );
}

export default CheckboxesTags