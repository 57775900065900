import React from "react"
import {useNavigate} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./buttons.scss"

export const GoBackButton: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="goBackButton" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon/>Назад
        </div>
    )
}