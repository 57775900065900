import React from "react";
import {HorizontalLinearStepper} from "../../components/stepper";

const Home: React.FC = () => {
    return (
        <>
            <HorizontalLinearStepper/>
        </>
    )
}

export default Home