import React from "react"
import "./profile.scss"
import EditProfile from "../../components/profile/EditProfile";
import {GET_CURRENT_USER} from "../../graphql/graphql-queries";
import {useQuery} from "@apollo/client";
import {User} from "../../types";
import Loader from "../../components/loader/Loader";

interface UserData {
    me: User
}

const Profile: React.FC = () => {
    const {data} = useQuery<UserData>(GET_CURRENT_USER)
    return (
        <div className="page profile">
            <div className="pageTop">
                <div className="pageTitle">
                    Профиль
                </div>
            </div>
            <div className="profileBottom">
                {data?.me ? <EditProfile user={data.me}/> : <Loader/>}
            </div>
        </div>
    )
}

export default Profile