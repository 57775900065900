import React, {useState} from "react"
import "./form.scss"
import {Code, Connect, Product, Size} from "../../types";
import {Box, Button, TextField} from "@mui/material";
import CheckboxesTags from "../checkboxes-tags/CheckboxesTags";
import AdminEditor from "../editor/AdminEditor";
import {SubmitHandler, useForm} from "react-hook-form";


interface IListForm {
    withName?: boolean,
    element?: Size | Connect | Code,
    saveHook: (element: ListFormData) => void,
    allProducts: Product[]
}

export interface ListFormData {
    id?: number,
    name?: string,
    content?: string,
    products: Product[]
}

interface IListFormInput {
    name: string
}

const ListForm: React.FC<IListForm> = ({withName, element, saveHook, allProducts}) => {
    const {register, handleSubmit, formState: {errors}} = useForm<IListFormInput>()
    const [products, setProducts] = useState(element?.products || [])
    const [content, setContent] = useState(element?.content)
    const onSubmit: SubmitHandler<IListFormInput> = ({name}) => {
        saveHook({id: element?.id, name, content, products})
    }
    return (
        <Box component="form" className="form" style={{width: "auto"}} onSubmit={handleSubmit(onSubmit)}>
            {element ? <TextField className="formInput" label="ID" defaultValue={element.id} type="text"
                                  InputProps={{readOnly: true}}/> : <></>}
            {/* @ts-ignore */}
            {withName && <TextField className="formInput" label="Имя" defaultValue={element?.name || ''} type="text"
                           error={!!errors.name} helperText={errors.name?.message}
                           {...register("name", {required: withName ? "Имя не может быть пустым" : false})}/>}
            <div className="formInput">
                <CheckboxesTags label="Относится к продуктам" defaultValue={products} options={allProducts}
                                changeHook={setProducts}/>
            </div>
            <div className="formInput">
                <AdminEditor onHtmlContentChange={setContent} initialHtmlContent={element?.content}/>
            </div>
            <Button variant="contained" type="submit">{element ? "Сохранить" : "Добавить"}</Button>
        </Box>
    )
}

export default ListForm