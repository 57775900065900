import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useAppApolloClient} from "./graphql/apollo-client";
import {ApolloProvider} from "@apollo/client";
import Home from "./pages/home/Home";
import Brands from "./pages/brand/Brands";
import Admin from "./pages/admin/Admin";
import {
    AddBrandPath,
    AddCodePath,
    AddConnectionPath,
    AddProductPath,
    AddSizePath,
    AdminPath,
    BrandPath,
    CodePath,
    ConnectionPath,
    EditBrandPath,
    EditCodePath,
    EditConnectionPath,
    EditProductPath,
    EditSizePath,
    LoginPath,
    ProductPath,
    SizePath,
    UserPath
} from "./constants";
import EditBrand from "./pages/brand/EditBrand";
import Products from "./pages/products/Products";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import {CookiesProvider} from "react-cookie";
import AddBrand from "./pages/brand/AddBrand";
import RequireAuth from "./components/RequireAuth";
import EditProduct from "./pages/products/EditProduct";
import AddProduct from "./pages/products/AddProduct";
import Sizes from "./pages/size/Sizes";
import EditSize from "./pages/size/EditSize";
import AddSize from "./pages/size/AddSize";
import Connections from "./pages/connection/Connections";
import EditConnection from "./pages/connection/EditConnection";
import AddConnection from "./pages/connection/AddConnection";
import Codes from "./pages/code/Codes";
import AddCode from "./pages/code/AddCode";
import EditCode from "./pages/code/EditCode";
import {PublicPage} from "./pages/public/PublicPage";

function App() {
    return (
        <CookiesProvider>
            <ApolloProvider client={useAppApolloClient()}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<PublicPage component={<Home/>}/>}/>
                            <Route path={LoginPath} element={<Login/>}/>
                            <Route element={<RequireAuth/>}>
                                <Route path={AdminPath} element={<Admin component={<Brands/>}/>}/>

                                <Route path={BrandPath} element={<Admin component={<Brands/>}/>}/>
                                <Route path={AddBrandPath} element={<Admin component={<AddBrand/>}/>}/>
                                <Route path={EditBrandPath} element={<Admin component={<EditBrand/>}/>}/>

                                <Route path={ProductPath} element={<Admin component={<Products/>}/>}/>
                                <Route path={AddProductPath} element={<Admin component={<AddProduct/>}/>}/>
                                <Route path={EditProductPath} element={<Admin component={<EditProduct/>}/>}/>

                                <Route path={SizePath} element={<Admin component={<Sizes/>}/>}/>
                                <Route path={AddSizePath} element={<Admin component={<AddSize/>}/>}/>
                                <Route path={EditSizePath} element={<Admin component={<EditSize/>}/>}/>

                                <Route path={ConnectionPath} element={<Admin component={<Connections/>}/>}/>
                                <Route path={AddConnectionPath} element={<Admin component={<AddConnection/>}/>}/>
                                <Route path={EditConnectionPath} element={<Admin component={<EditConnection/>}/>}/>

                                <Route path={CodePath} element={<Admin component={<Codes/>}/>}/>
                                <Route path={AddCodePath} element={<Admin component={<AddCode/>}/>}/>
                                <Route path={EditCodePath} element={<Admin component={<EditCode/>}/>}/>

                                <Route path={UserPath} element={<Admin component={<Profile/>}/>}/>
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ApolloProvider>
        </CookiesProvider>
    );
}

export default App;
