import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import "./actions.scss"

interface IActions {
    editCallback: () => void
    removeCallback: () => void
}

const Actions: React.FC<IActions> = ({editCallback, removeCallback}) => (
    <div className="action">
        <div className="editButton" onClick={editCallback}>
            <EditIcon/>
        </div>
        <div className="deleteButton" onClick={removeCallback}>
            <ClearIcon/>
        </div>
    </div>
)

export default Actions