import React, {useState} from "react"
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_SIZE_MUTATION, GET_ALL_SIZES} from "../../graphql/graphql-queries";
import {Size} from "../../types";
import Loader from "../../components/loader/Loader";
import {Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {Link} from "react-router-dom";
import {AddSizePath, SizePath} from "../../constants";
import ListTable from "../../components/list/ListTable";

interface SizesData {
    sizes: Size[]
}

const Sizes:React.FC = () => {
    const [sizes, setSizes] = useState<Size[]>([])
    const {loading} = useQuery<SizesData>(GET_ALL_SIZES, {
        onCompleted: data => setSizes(data.sizes)
    })
    const [deleteMutation, {client}] = useMutation(DELETE_SIZE_MUTATION)
    const deleteSize = async (id: string | number) => {
        setSizes(sizes.filter(size => size.id !== id))
        await deleteMutation({variables: {id: id}});
        await client.clearStore()
    }
    return(
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Установочные размеры</div>
                <Link to={AddSizePath} style={{textDecoration: "none"}}>
                <Fab size="medium" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
                </Link>
            </div>
            {loading ? <Loader/> : <ListTable elements={sizes} elementHome={SizePath} deleteHook={deleteSize}/>}
        </div>
    )
}

export default Sizes