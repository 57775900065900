import React, {useState} from "react";
import "./products.scss"
import {useMutation, useQuery} from "@apollo/client";
import {DELETE_PRODUCT_MUTATION, GET_ALL_PRODUCTS} from "../../graphql/graphql-queries";
import {Product} from "../../types";
import Loader from "../../components/loader/Loader";
import {Fab} from "@mui/material";
import {Link} from "react-router-dom";
import {AddProductPath} from "../../constants";
import ProductTable from "../../components/list/product-list/ProductTable";
import AddIcon from "@mui/icons-material/Add";
import Search from "../../components/search/Search";

interface ProductsData {
    products: Product[]
}

const Products: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const {data, loading} = useQuery<ProductsData>(GET_ALL_PRODUCTS, {
        onCompleted: d => setProducts(d.products)
    })

    const [deleteProductMutation, {client}] = useMutation(DELETE_PRODUCT_MUTATION)
    const deleteProduct = async (id: string | number) => {
        await deleteProductMutation({variables: {id: id}});
        await client.clearStore()
        setProducts(products.filter(product => product.id !== id))
    }

    function onChange({target: {value}} : React.ChangeEvent<HTMLInputElement>) {
        if (data?.products && value !=='') {
            const filteredProducts = data.products.filter(product => product.name?.toLowerCase().includes(value.toLowerCase()));
            setProducts(filteredProducts);
        } else if (data?.products) {
            setProducts(data.products)
        }
    }
    return (
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Продукты</div>
                <Link to={AddProductPath} style={{textDecoration: "none"}}>
                    <Fab size="medium" color="primary" aria-label="add">
                        <AddIcon />
                    </Fab>
                </Link>
                <Search onChange={onChange}/>
            </div>
            {loading ? <Loader/> : <ProductTable products={products} deleteProduct={deleteProduct}/>}
        </div>
    )
}

export default Products