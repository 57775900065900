import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {Box, Button, LinearProgress, Stack, TextField} from "@mui/material";
import "./brand-form.scss"
import {Brand, UploadResponse} from "../../types";
import DeleteIcon from '@mui/icons-material/Delete';
import {PhotoCamera} from "@mui/icons-material";
import {useMutation} from "@apollo/client";
import {DELETE_BRAND_MUTATION, SAVE_BRAND_MUTATION, UPLOAD_IMAGE_MUTATION} from "../../graphql/graphql-queries";
import {BrandPath} from "../../constants";
import {useNavigate} from "react-router-dom";

interface IBrandFormInput {
    name: string
}

interface IEditBrand {
    brand?: Brand
}

interface UploadData {
    uploadImage: UploadResponse
}

const BrandForm: React.FC<IEditBrand> = ({brand}) => {
    const {register, handleSubmit} = useForm<IBrandFormInput>()
    const [logoKey, setLogoKey] = useState(brand?.logo)
    const [logoUrl, setLogoUrl] = useState(brand?.logoUrl)
    const [uploadMutation, {loading}] = useMutation<UploadData>(UPLOAD_IMAGE_MUTATION)
    const [saveBrandMutation, {client}] = useMutation<Brand>(SAVE_BRAND_MUTATION);
    const [deleteBrandMutation] = useMutation<Brand>(DELETE_BRAND_MUTATION);
    const navigate = useNavigate();

    const onChange = ({target: {validity, files}}: React.ChangeEvent<HTMLInputElement>) => {
        if (validity.valid && files) {
            uploadMutation({variables: {files}})
                .then(response => {
                    setLogoKey(response.data?.uploadImage.key)
                    setLogoUrl(response.data?.uploadImage.url)
                });
        }
    }

    const deleteBrand = async () => {
        await deleteBrandMutation({variables: {id: brand?.id}})
        await client.clearStore()
        navigate(BrandPath)
    };

    const onSubmit: SubmitHandler<IBrandFormInput> = data => {
        saveBrandMutation({variables: {brand: {id: brand?.id, name: data.name, logo: logoKey}}})
            .then(async () => {
                await client.clearStore();
                navigate(BrandPath)
            });
    }

    return (
        <Box component="form" className="editBrandForm" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className="logo">
                <img src={logoUrl} alt=""/>
                {!loading
                    ? <Button variant="outlined" component="label" startIcon={<PhotoCamera/>}>
                        Загрузить лого
                        <input type="file"
                               onChange={onChange}
                               hidden/>
                    </Button>
                    : <></>}
                {loading ? <LinearProgress/> : <></>}
            </div>
            {brand ? <TextField className="formInput" label="ID" defaultValue={brand?.id} type="text"
                                InputProps={{readOnly: true}}/> : <></>}
            <TextField className="formInput" label="Имя Бренда" defaultValue={brand?.name} type="text"
                       {...register("name")}/>
            <Stack direction="column" spacing={2}>
                {brand ? <Button onClick={deleteBrand} variant="outlined" color="error" endIcon={<DeleteIcon/>}
                                 fullWidth>Удалить</Button> : <></>}
                <Button variant="contained" fullWidth type="submit">{brand ? "Сохранить" : "Добавить"}</Button>
            </Stack>
        </Box>
    )
}

export default BrandForm