import React, {useState} from "react";
import "./edit-profile.scss"
import {Box, TextField} from "@mui/material";
import {SubmitHandler, useForm} from "react-hook-form";
import {User} from "../../types";

interface IEditProfileFormInput {
    email: string,
    oldPassword: string,
    password: string,
    confirmation: string
}

interface IEditProfile {
    user: User
}

const EditProfile: React.FC<IEditProfile> = ({user}) => {

    const {register, handleSubmit} = useForm<IEditProfileFormInput>()
    console.log(user)
    //TODO delete mock data
    const isAdmin: boolean = true

    const [currentPasswordMatch] = useState(true)
    const [currentPasswordHelpText] = useState('')
    const [newPasswordMatch, setNewPasswordMatch] = useState(true)
    const [helpText, setHelpText] = useState('')

    const onSubmit: SubmitHandler<IEditProfileFormInput> = data => {

        if (data.password !== data.confirmation) {
            setNewPasswordMatch(false)
            setHelpText("Пароли не совпадают")
        } else {
            setNewPasswordMatch(true)
        }
    }
    return (
        <div className="editProfile">
            <div className="editTop">
                <div className="email">{user?.email}</div>
                {isAdmin ? <div className="isAdmin">ADMIN</div> : <></>}
            </div>
            <Box component="form" className="editProfileForm" autoComplete="off"
                 onSubmit={handleSubmit(onSubmit)}>
                <TextField label="Старый пароль" type="password"
                           error={!currentPasswordMatch}
                           helperText={currentPasswordHelpText}
                           variant="outlined" {...register("oldPassword")}/>
                <TextField label="Новый пароль" type="password"
                           error={!newPasswordMatch}
                           variant="outlined" {...register("password")}/>
                <TextField label="Подтвердите новый пароль" type="password"
                           error={!newPasswordMatch}
                           helperText={helpText}
                           variant="outlined" {...register("confirmation")}/>
            </Box>
        </div>
    )
}

export default EditProfile;