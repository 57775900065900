import React from "react";
import "./list.scss"
import {DataGrid, GridColDef, GridRowHeightParams, GridValueGetterParams} from "@mui/x-data-grid";
import {Code, Connect, Product, Size} from "../../types";
import {Link, useNavigate} from "react-router-dom";
import {ProductPath} from "../../constants";
import Attention from "./attention/Attention";
import Actions from "./actions/Actions";

const productColumnWidth: number = 700

interface ISizeTable {
    elements: Size[] | Connect[] | Code[],
    elementHome: string,
    deleteHook: (id: number) => void
}

const ListTable: React.FC<ISizeTable> = ({elements, elementHome, deleteHook}) => {
    const navigate = useNavigate();
    const handleGetRowHeight = React.useCallback(({model: {products}}: GridRowHeightParams) => {
        const maxLettersPerLine = productColumnWidth / 100 * 14
        const total: number = products.map((product: Product) => product.name)
            .reduce((previous: string, current: string) => previous + current, "").length
        if (total > maxLettersPerLine) {
            return 35 * Math.ceil(total / maxLettersPerLine)
        }
        return null;
    }, []);

    const productsColumn = [
        {
            field: "products", headerName: "Относится к продуктам", width: productColumnWidth, sortable: false,
            renderCell: (params: GridValueGetterParams) => {
                return (
                    <>
                        {params.row.products && params.row.products.length > 0 ?
                            <ul className="cellItems">
                                {params.row.products?.map((product: Product) =>
                                    <Link key={product.id} to={ProductPath + "/" + product.id}
                                          style={{textDecoration: "none"}}>
                                        <li className="item">
                                            {product.name}
                                        </li>
                                    </Link>
                                )}
                            </ul>
                            : <Attention text="Внимание лист не принадлежит ни к одному из продуктов"/>}</>)
            }
        }]
    const actionColumn = [
        {
            field: "action",
            headerName: "Действие",
            width: 100,
            sortable: false,
            renderCell: (params: GridValueGetterParams) => {
                return (
                    <Actions editCallback={() => navigate(elementHome + "/" + params.id)}
                             removeCallback={() => deleteHook(params.id as number)}/>
                )
            }
        }
    ]
    const idColumn: GridColDef[] = [{field: "id", headerName: "ID", width: 70}]
    const nameColumn: GridColDef[] = [{field: "name", headerName: "Имя листа", width: 180}]

    function getColumns(){
        // @ts-ignore
        if (elements[0].name !== undefined) {
            return idColumn.concat(nameColumn).concat(productsColumn).concat(actionColumn)
        }
        return idColumn.concat(productsColumn).concat(actionColumn)
    }

    return (
        <div className="list">
            <div style={{width: '100%'}}>
                <DataGrid
                    rows={elements}
                    autoHeight={true}
                    getRowHeight={handleGetRowHeight}
                    columns={getColumns()}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    sx={{border: 0}}
                />
            </div>
        </div>
    )
}

export default ListTable