import React from "react";
import ProductForm from "../../components/product/product-form/ProductForm";

const AddProduct:React.FC = () => {
    return(
        <div className="page">
            <div className="pageTop">
                <div className="pageTitle">Добавить продукт</div>
            </div>
            <ProductForm/>
        </div>
    )
}

export default AddProduct