import React from "react";
import "./card.scss"

interface ICard {
    src: string,
}

export const Card:React.FC<ICard> = ({src}) => (
        <div className="card">
            <img src={src} alt=""/>
        </div>
)