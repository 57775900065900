import React from "react";
import ListForm, {ListFormData} from "../../components/form/ListForm";
import {useMutation, useQuery} from "@apollo/client";
import {GET_PRODUCTS_NAMES_AND_IDS, SAVE_CONNECTION_MUTATION} from "../../graphql/graphql-queries";
import {Product} from "../../types";
import {ConnectionPath} from "../../constants";
import {useNavigate} from "react-router-dom";
import Loader from "../../components/loader/Loader";

interface ProductsData {
    products: Product[]
}

const AddConnection:React.FC = ()=> {
    const navigate = useNavigate()
    const productsQuery = useQuery<ProductsData>(GET_PRODUCTS_NAMES_AND_IDS)
    const [connectMutation, {client}] = useMutation(SAVE_CONNECTION_MUTATION)
    async function saveConnection(listFormData: ListFormData) {
        await connectMutation({
            variables: {
                connection: {
                    id: listFormData.id,
                    content: listFormData.content,
                    productIds: listFormData.products.map(value => value.id)
                }
            }
        })
        await client.clearStore()
        navigate(ConnectionPath)
    }
    return(
        <div className="page">
            <div className="pageTop productsTop">
                <div className="pageTitle">Добавить лист питания и МБС</div>
            </div>
            {productsQuery.data ? <ListForm
                allProducts={productsQuery.data.products}
                saveHook={saveConnection}
            />: <Loader/>}
        </div>
    )
}

export default AddConnection