import React from "react"
import BrandForm from "../../components/brand/BrandForm";

const EditBrand: React.FC = () => {

    return (
        <div className="page">
            <div className="pageTop">
                <div className="pageTitle">Добавить бренд</div>
            </div>
            <BrandForm/>
        </div>
    )
}

export default EditBrand