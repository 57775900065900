import React from "react"
import "./brand-card.scss"
import {useNavigate} from "react-router-dom";
import {BrandPath} from "../../constants";

interface ICard {
    id: number,
    img?: string
    name: string,
}

const BrandCard: React.FC<ICard> = ({img, name, id}) => {
    const navigation = useNavigate();
    return (
        <div className="brand-card" onClick={() => navigation(BrandPath + "/" + id)}>
            {img ? <img src={img} alt=""/> :
                <div className="cardHeader">{name}</div>
            }
        </div>
    )
}


export default BrandCard;