import React from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {GET_PRODUCT} from "../../graphql/graphql-queries";
import {Product} from "../../types";
import Loader from "../../components/loader/Loader";
import ProductForm from "../../components/product/product-form/ProductForm";

interface ProductData {
    product: Product
}

const EditProduct: React.FC = () => {
    const {id} = useParams()
    const {data, loading} = useQuery<ProductData>(GET_PRODUCT, {variables: {id}})
    return (
        <div className="page">
            <div className="pageTop">
                <div className="pageTitle">Редактировать продукт</div>
            </div>
            {loading ? <Loader/> : <ProductForm product={data?.product}/>}
        </div>
    )
}

export default EditProduct