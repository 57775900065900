import React from "react";
import "./public.scss"
import {Navbar} from "../../components/navbar";

interface IPublicPage {
    component: React.ReactNode
}

export const PublicPage: React.FC<IPublicPage> = ({component}) => (
    <div className="public">
        <Navbar/>
        <div className="container">
            {component}
        </div>
    </div>
)