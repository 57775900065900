import React, {useState} from "react";
import "./style.scss"
import {SubmitHandler, useForm} from "react-hook-form";
import {useMutation} from "@apollo/client";
import {LoginResponse} from "../../types";
import {LOGIN_MUTATION} from "../../graphql/graphql-queries";
import {useLocation} from "react-router-dom";
import {Location} from "history";
import {AdminPath} from "../../constants";
import {useAuthToken} from "../../graphql/auth";

interface ILoginInput {
    email: string,
    password: string
}

type LocationState = {
    from: Location
}

export const Login: React.FC = () => {
    const {register, handleSubmit} = useForm<ILoginInput>()
    const [error, setError] = useState(false);
    const {setAuthToken}= useAuthToken()
    const location = useLocation();
    const locationState = location.state as LocationState;
    const from = locationState?.from.pathname || AdminPath

    const [mutation] = useMutation<LoginResponse>(LOGIN_MUTATION,{
        onCompleted: data => {
            setAuthToken(data.login)
            window.open(from,"_self")
        },
        onError: () => setError(true)
    })
    const onSubmit: SubmitHandler<ILoginInput> = ({email, password}) => {
        mutation({variables: {email, password}})
    }
    return (
        <div className="login">
            <div>
                <span className="logo">AC&nbsp;<span>Service</span>
                </span>
                <form className="loginForm" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className="some">
                            <input type="text" autoComplete="off"
                                   placeholder="Логин" {...register("email")}/>
                        </div>
                        <div className="some">
                            <input type="password" placeholder="Пароль" {...register("password")}/>
                        </div>
                        {error ? <div className="errorText">Неправильный email или пароль</div> : <></>}
                        <button type="submit">Войти</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login