import React from "react";
import "./admin.scss"
import Sidebar from "../../components/sidebar/Sidebar";
import {GoBackButton} from "../../components/button/Buttons";

interface IAdmin {
    component: React.ReactNode
}

const Admin: React.FC<IAdmin> = ({component}) => {
    return (
        <div className="admin">
            <Sidebar/>
            <div className="adminContainer">
            <GoBackButton/>
            {component}
            </div>
        </div>
    )
}

export default Admin;
