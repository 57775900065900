import {useCookies} from "react-cookie";
import jwt_decode, {JwtPayload} from "jwt-decode";
import {useApolloClient} from "@apollo/client";
import {User} from "../types";
import {AuthCookieName} from "../constants";
import {useNavigate} from "react-router-dom";

interface AuthProps {
    user: User | undefined,
    setAuthToken: (authToken: User) => void,
    removeAuthToken: () => void

}

export function useAuthToken(): AuthProps {
    const [cookies, setCookie, removeCookie] = useCookies([AuthCookieName]);
    const setAuthToken = (user: User) => {
        const decoded = jwt_decode<JwtPayload>(user.token);
        const expiresDate = decoded.exp ? new Date(decoded.exp * 1000) : undefined
        setCookie(AuthCookieName, user, {
            expires: expiresDate
        })
    };
    return {
        user: cookies[AuthCookieName],
        setAuthToken: setAuthToken,
        removeAuthToken: () => removeCookie(AuthCookieName)
    }
}

export const useLogout = () => {
    const navigate = useNavigate()
    const {removeAuthToken} = useAuthToken();
    const apolloClient = useApolloClient();
    return async () => {
        await apolloClient.clearStore(); // we remove all information in the store
        removeAuthToken();
        navigate("/")
    };
};

export const checkExpires = (token: string) => {
    const decoded = jwt_decode<JwtPayload>(token);
    if (decoded.exp && decoded.exp * 1000 <= new Date().getTime()) {
        window.open('/login','_self');
    }
}