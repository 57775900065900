import React from "react";
import "./style.scss"

interface IChooseType {
    onClick: (type: string) => void
}

export const ChooseType: React.FC<IChooseType> = ({onClick}) => {

    const types = [{
        name: "RAC",
        description: "серии бытовых кондиционеров"
    }, {
        name: "LCAC",
        description: "серии полупромышленных кондиционеров"
    }]

    return (
        <div className="type-group">
            {types.map((type, index) =>
                <div className="type" key={index} onClick={() => onClick(type.name)}>
                    <div className="header">{type.name}</div>
                    <div className="description">{type.description}</div>
                </div>
            )}

        </div>
    )
}